
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import InfoModal from '@/components/InfoModal.vue'
import MasonryWall from '@yeger/vue-masonry-wall'
import StoryCard from '@/components/StoryCard.vue'
import QuoteCard from '@/components/QuoteCard.vue'
import ImageCard from '@/components/ImageCard.vue'

export default defineComponent({
  name: 'Search',
  components: {
    InfoModal,
    MasonryWall,
    StoryCard,
    QuoteCard,
    ImageCard
  },
  data () {
    return {
      mergedList: [],
      count: 12,
      increment: 12
    }
  },
  computed: {
    showState () {
      return this.$route.params.query.toString().length > 0
    },
    query () {
      return this.$route.params.query.toString()
    },
    filter () {
      return this.$route.params.filter
    },
    filterAll () {
      return this.$route.params.filter === 'all' || this.$route.params.filter === ''
    },
    mergedArr () {
      const mergedArr = [] as any
      let newArr = []
      if (this.filter === 'all' || this.filter === '') {
        newArr = mergedArr.concat((this as any).stories, (this as any).quotes, (this as any).images)
      } if (this.filter === 'quotes') {
        newArr = mergedArr.concat((this as any).quotes)
      } if (this.filter === 'stories') {
        newArr = mergedArr.concat((this as any).stories)
      } if (this.filter === 'images') {
        newArr = mergedArr.concat((this as any).images)
      }
      return newArr.sort((a:any, b:any) => (a.createdDate > b.createdDate) ? 1 : -1)
    },
    ...mapGetters({
      images: 'getImagesData',
      imagesLoaded: 'getImagesLoaded',
      quotes: 'getQuotesQueryData',
      quotesLoaded: 'getQuotesQueryLoaded',
      stories: 'getStoriesQueryData',
      storiesLoaded: 'getStoriesQueryLoaded',
      sortBy: 'getSortBy'
    })
  },
  mounted () {
    window.scrollTo(0, 0)
    if (this.$route.params.query) {
      this.searchForQuery()
    } if (
      this.$route.params.query &&
      (this.$route.params.filter === '' || this.$route.params.filter === 'favorites')
    ) {
      this.redirectAll()
    }
  },
  watch: {
    $route (to, from) {
      if (
        to.params.query &&
        to.params.filter === ''
      ) {
        this.redirectAll()
      } if (
        this.$route.params.query &&
        to.params.query !== from.params.query
      ) {
        this.$store.dispatch('setImagesLoadedState', false)
        this.$store.dispatch('setQuotesQueriesLoadedState', false)
        this.$store.dispatch('setStoriesQueriesLoadedState', false)
        this.query = this.$route.params.query.toString()
        this.filter = this.$route.params.filter
        this.searchForQuery()
      } if (
        this.$route.params.query &&
        to.params.query !== from.params.query &&
        this.$route.params.filter &&
        to.params.filter !== from.params.filter
      ) {
        this.query = this.$route.params.query.toString()
        this.filter = this.$route.params.filter
        this.searchForQuery()
      } if (
        to.params.query === from.params.query &&
        to.params.filter !== from.params.filter
      ) {
        this.append('switch', to.params.filter)
      } if (to.name !== 'Search') {
        this.clearQueryResults()
      }
    },
    mergedArr () {
      this.mergedList = this.mergedArr.slice(0, this.increment)
    },
    sortBy () {
      this.sortResults()
    }
  },
  methods: {
    sortResults: function (): void {
      let sortedArr = [] as any
      if (this.sortBy === 'A-Z') {
        sortedArr = this.mergedList.sort((a:any, b:any) => (a.linkedParticipantsNames[0] > b.linkedParticipantsNames[0]) ? 1 : -1)
      } if (this.sortBy === 'Most Recent') {
        sortedArr = this.mergedList.sort((a:any, b:any) => (a.createdDate > b.createdDate) ? 1 : -1)
      }
      this.mergedList = sortedArr
    },
    append: function (type:string, filter:string): void {
      // Add more items into the list if button clicked (type:increment)
      // or if no items of corresponding type are in list when (type:switch)
      if (type === 'increment') {
        this.count = this.count + this.increment
      } if (type === 'switch' && filter !== 'all') {
        const found = this.mergedList.filter((item:any) => item.type === this.pluralizeName(1, filter))
        if (found.length === 0) {
          this.count = this.count + this.increment
        }
      }
      this.mergedList = this.mergedArr.slice(0, this.count)
    },
    redirectAll: function (): void {
      this.$router.push({ name: 'Search', params: { query: this.query, filter: 'all' } })
    },
    pluralizeName: function (length:number, value:string): string {
      if (length === 1) {
        if (value === 'Stories') {
          return 'Story' as string
        }
        return value.slice(0, -1) as string
      } else {
        return value
      }
    },
    filterStories: function (): boolean {
      if (this.filter) {
        const findType = this.filter.toString().search('stories') >= 0
        return (this.stories && this.storiesLoaded) &&
        (findType || this.filterAll)
      } else {
        return false
      }
    },
    filterQuotes: function (): boolean {
      if (this.filter) {
        const findType = this.filter.toString().search('quotes') >= 0
        return (this.quotes && this.quotesLoaded) &&
        (findType || this.filterAll)
      } else {
        return false
      }
    },
    filterImages: function (): boolean {
      if (this.filter) {
        const findType = this.filter.toString().search('images') >= 0
        return (this.images && this.imagesLoaded) &&
        (findType || this.filterAll)
      } else {
        return false
      }
    },
    setQuery: function (): void {
      this.$router.push({ name: 'Search', params: { query: this.query, filter: this.filter } })
    },
    clearQueryResults: function (): void {
      this.$store.dispatch('clearQuotesData')
      this.$store.dispatch('clearStoriesData')
      this.$store.dispatch('clearImagesData')
    },
    searchForQuery (): void {
      this.count = 12
      const searchQuery = String(this.$route.params.query).toLowerCase()
      this.$store.dispatch('getStoriesData', {
        type: 'filterBy',
        query: `
          OR(
            FIND("${searchQuery}", LOWER({title})),
            FIND("${searchQuery}", LOWER({title})),
            FIND("${searchQuery}", LOWER({linkedTags})),
            SEARCH("${searchQuery}", LOWER(ARRAYJOIN(linkedParticipants, ""))),
            SEARCH("${searchQuery}", LOWER(ARRAYJOIN(programsName, "")))
          )`
      })
      this.$store.dispatch('getQuotesData', {
        type: 'filterBy',
        query: `
          OR(
            FIND("${searchQuery}", LOWER({body})),
            FIND("${searchQuery}", LOWER({linkedTags})),
            SEARCH("${searchQuery}", LOWER(ARRAYJOIN(linkedTagsNames, ""))),
            SEARCH("${searchQuery}", LOWER(ARRAYJOIN(linkedParticipants, ""))),
            SEARCH("${searchQuery}", LOWER(ARRAYJOIN(programsName, "")))
          )
        `
      })
      this.$store.dispatch('getImagesData', {
        type: 'filterBy',
        query: `
          OR(
            FIND("${searchQuery}", LOWER({description})),
            FIND("${searchQuery}", LOWER({linkedTags})),
            SEARCH("${searchQuery}", LOWER(ARRAYJOIN(linkedParticipants, ""))),
            SEARCH("${searchQuery}", LOWER(ARRAYJOIN(programsName, "")))
          )`
      })
    }
  }
})
